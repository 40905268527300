import React, { createContext, useContext } from 'react';

import { logEvent } from 'firebase/analytics';

import { analytics } from '../firebase';



interface AnalyticsContextType {

  trackEvent: (eventName: string, eventCategory: string, eventLabel?: string, value?: number) => void;

}



const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);



export function useAnalytics() {

  const context = useContext(AnalyticsContext);

  if (context === undefined) {

    throw new Error('useAnalytics must be used within an AnalyticsProvider');

  }

  return context;

}



export function AnalyticsProvider({ children }: { children: React.ReactNode }) {

  const trackEvent = (

    eventName: string,

    eventCategory: string,

    eventLabel?: string,

    value?: number

  ) => {

    try {

      if (analytics) {

        logEvent(analytics, eventName, {

          event_category: eventCategory,

          event_label: eventLabel,

          value: value,

        });

      }

    } catch (error) {

      console.error('Error tracking event:', error);

    }

  };



  return (

    <AnalyticsContext.Provider value={{ trackEvent }}>

      {children}

    </AnalyticsContext.Provider>

  );

} 
