import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FileText, Settings, Layout, MessageSquare } from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import logo from "../assets/logo.png";

interface SidebarProps {
  collapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed }) => {
  const location = useLocation();
  const { isDarkMode } = useTheme();
  const isDashboard = location.pathname === "/dashboard";

  const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: Layout },
    { name: "Documents", href: "/documents", icon: FileText },
    { name: "Feedback", href: "/feedback", icon: MessageSquare },
    { name: "Settings", href: "/settings", icon: Settings },
  ];

  return (
    <div
      className={`${
        collapsed ? "w-16" : "w-64"
      } transition-all duration-300 ease-in-out flex-shrink-0 ${
        isDarkMode ? "bg-gray-900" : "bg-white"
      } border-r ${
        isDarkMode ? "border-gray-700" : "border-gray-200"
      } flex flex-col h-full select-none`}
    >
      <div
        className={`h-16 flex items-center ${
          collapsed ? "justify-center" : "px-4"
        }`}
      >
        <div
          className={`flex items-center cursor-default select-none ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          <img src={logo} alt="Unsloth" className="h-8 w-8" />
          {!collapsed && (
            <span className="ml-2 text-xl font-bold">Unsloth</span>
          )}
        </div>
      </div>
      <nav className="flex-1 px-2 py-4 space-y-1">
        {navigation.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.href;

          return (
            <Link
              key={item.name}
              to={item.href}
              className={`${
                isActive
                  ? "bg-blue-100 text-blue-900 dark:bg-blue-900 dark:text-blue-100"
                  : `${
                      isDarkMode
                        ? "text-gray-300 hover:bg-gray-700"
                        : "text-gray-600 hover:bg-gray-50"
                    }`
              } group flex items-center ${
                collapsed ? "justify-center" : "px-2"
              } py-2 text-sm font-medium rounded-md select-none`}
              title={item.name}
            >
              <Icon className={`${collapsed ? "h-6 w-6" : "mr-3 h-6 w-6"}`} />
              {!collapsed && item.name}
            </Link>
          );
        })}
      </nav>

      {/* Beta Tag */}
      <div className={`px-2 py-4 ${collapsed ? "text-center" : ""}`}>
        <span
          className={`px-2 py-1 text-xs font-medium rounded-full select-none ${
            isDarkMode
              ? "bg-blue-900 text-blue-200"
              : "bg-blue-100 text-blue-800"
          }`}
        >
          Beta
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
