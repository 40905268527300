import React, { useState, useEffect } from "react";
import PageLayout from "../components/PageLayout";
import { FileText, Folder, Download, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import { useTheme } from "../context/ThemeContext";

interface Document {
  id: number;
  name: string;
  type: string;
  date: string;
  description?: string;
  emails?: string;
  generationTime?: string; // Add generation time field
}

const Documents: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  // Load documents when component mounts
  useEffect(() => {
    const savedDocuments = JSON.parse(localStorage.getItem('documents') || '[]');
    setDocuments(savedDocuments);
  }, []);

  const deleteDocument = (id: number) => {
    const updatedDocs = documents.filter((doc) => doc.id !== id);
    setDocuments(updatedDocs);
    localStorage.setItem('documents', JSON.stringify(updatedDocs));
  };

  const toggleRow = (id: number) => {
    setExpandedRows(prev => 
      prev.includes(id) 
        ? prev.filter(rowId => rowId !== id)
        : [...prev, id]
    );
  };

  return (
    <PageLayout title="Documents" disableLogoLink={true}>
      <div className="max-w-4xl mx-auto">
        <div className="mb-6 flex justify-between items-center">
          <h2 className={`text-2xl font-semibold ${isDarkMode ? "text-white" : "text-gray-800"}`}>
            Your Documents
          </h2>
          <button
            className={`flex items-center px-4 py-2 rounded-md ${
              isDarkMode ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-500 hover:bg-blue-600"
            } text-white transition-colors`}
          >
            <FileText className="w-5 h-5 mr-2" />
            New Document
          </button>
        </div>
        <div className={`overflow-hidden rounded-lg ${isDarkMode ? "bg-gray-800" : "bg-white"} shadow`}>
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className={isDarkMode ? "bg-gray-700" : "bg-gray-50"}>
              <tr>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-500"
                } uppercase tracking-wider`}>
                  Name
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-500"
                } uppercase tracking-wider`}>
                  Type
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-500"
                } uppercase tracking-wider`}>
                  Date
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-500"
                } uppercase tracking-wider`}>
                  Generation Time
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-500"
                } uppercase tracking-wider`}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className={`${
              isDarkMode ? "bg-gray-800" : "bg-white"
            } divide-y divide-gray-200 dark:divide-gray-700`}>
              {documents.map((doc) => (
                <React.Fragment key={doc.id}>
                  <tr 
                    onClick={() => toggleRow(doc.id)}
                    className={`cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors`}
                  >
                    <td className={`px-6 py-4 whitespace-nowrap ${
                      isDarkMode ? "text-gray-200" : "text-gray-900"
                    }`}>
                      <div className="flex items-center">
                        <Folder className={`flex-shrink-0 h-5 w-5 ${
                          isDarkMode ? "text-gray-400" : "text-gray-400"
                        }`} />
                        <span className="ml-2">{doc.name}</span>
                        {expandedRows.includes(doc.id) ? 
                          <ChevronUp className="ml-2 w-4 h-4" /> : 
                          <ChevronDown className="ml-2 w-4 h-4" />
                        }
                      </div>
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${
                      isDarkMode ? "text-gray-200" : "text-gray-900"
                    }`}>
                      {doc.type}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${
                      isDarkMode ? "text-gray-200" : "text-gray-900"
                    }`}>
                      <div className="flex flex-col">
                        <span>{new Date(doc.date).toLocaleDateString()}</span>
                        <span className={`text-sm ${
                          isDarkMode ? "text-gray-400" : "text-gray-500"
                        }`}>
                          {new Date(doc.date).toLocaleTimeString()}
                        </span>
                      </div>
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${
                      isDarkMode ? "text-gray-200" : "text-gray-900"
                    }`}>
                      <span className={`${
                        isDarkMode ? "text-gray-200" : "text-gray-900"
                      }`}>
                        {doc.generationTime ? `${doc.generationTime}s` : "N/A"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // Add download logic here
                        }}
                        className={`text-blue-600 hover:text-blue-900 ${
                          isDarkMode ? "hover:text-blue-400" : ""
                        } mr-4`}
                      >
                        <Download className="w-5 h-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteDocument(doc.id);
                        }}
                        className={`text-red-600 hover:text-red-900 ${
                          isDarkMode ? "hover:text-red-400" : ""
                        }`}
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                  {expandedRows.includes(doc.id) && (
                    <tr className={`${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}>
                      <td colSpan={5} className="px-6 py-4">
                        <div className="space-y-3">
                          <div>
                            <h4 className={`font-medium ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}>
                              Project Description:
                            </h4>
                            <p className={`mt-1 ${
                              isDarkMode ? "text-gray-400" : "text-gray-600"
                            }`}>
                              {doc.description || "No description available"}
                            </p>
                          </div>
                          <div>
                            <h4 className={`font-medium ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}>
                              Recipients:
                            </h4>
                            <p className={`mt-1 ${
                              isDarkMode ? "text-gray-400" : "text-gray-600"
                            }`}>
                              {doc.emails || "No recipients specified"}
                            </p>
                          </div>
                          <div>
                            <h4 className={`font-medium ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}>
                              Generation Time:
                            </h4>
                            <p className={`mt-1 ${
                              isDarkMode ? "text-gray-400" : "text-gray-600"
                            }`}>
                              {doc.generationTime ? `${doc.generationTime} seconds` : "Time not recorded"}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </PageLayout>
  );
};

export default Documents;
