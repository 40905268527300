import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Mail, Lock } from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
export default function SignUp() {
  console.log("SignUp component rendering");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { signup } = useAuth();
  useEffect(() => {
    console.log("Theme context:", { isDarkMode });
    console.log("Auth context available:", !!signup);
  }, [isDarkMode, signup]);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    console.log("Form submitted with email:", email);
    if (!email.endsWith("@ashlingpartners.com")) {
      setError(
        "Only @ashlingpartners.com email addresses are allowed to register"
      );
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }
    try {
      await signup(email, password);
      navigate("/dashboard");
    } catch (err: any) {
      console.error("Signup error:", err);
      setError(err.message || "An error occurred during signup");
    }
  };
  if (!isDarkMode && typeof isDarkMode !== "boolean") {
    console.error("Theme context not available");
    return <div>Loading...</div>;
  }
  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        isDarkMode ? "bg-gray-900" : "bg-gray-50"
      }`}
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5" />
        <div className="absolute -top-1/2 -left-1/2 w-full h-full transform rotate-12 bg-gradient-to-br from-blue-500/3 to-purple-500/3 rounded-full" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full transform -rotate-12 bg-gradient-to-br from-purple-500/3 to-blue-500/3 rounded-full" />
      </div>
      {/* Content */}
      <div className="relative z-10 w-full max-w-md px-6">
        <div
          className={`${
            isDarkMode
              ? "bg-gray-800/50 backdrop-blur-xl border border-gray-700"
              : "bg-white/80 backdrop-blur-xl shadow-xl"
          } rounded-2xl p-8`}
        >
          <div className="text-center mb-8">
            <h1
              className={`text-3xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Create Account
            </h1>
            <p
              className={`mt-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              Join Unsloth to streamline your documentation
            </p>
          </div>
          {error && (
            <div className="mb-6 p-4 rounded-lg bg-red-500/10 border border-red-500/20 text-red-500">
              {error}
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                className={`block text-sm font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Email address
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail
                    className={`h-5 w-5 ${
                      isDarkMode ? "text-gray-500" : "text-gray-400"
                    }`}
                  />
                </div>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg transition-colors ${
                    isDarkMode
                      ? "bg-gray-900/50 border-gray-600 text-white placeholder-gray-500"
                      : "bg-white/50 border-gray-300 text-gray-900 placeholder-gray-400"
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="you@ashlingpartners.com"
                />
              </div>
              <p
                className={`mt-2 text-sm ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                Only @ashlingpartners.com email addresses are allowed
              </p>
            </div>
            <div>
              <label
                className={`block text-sm font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Password
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock
                    className={`h-5 w-5 ${
                      isDarkMode ? "text-gray-500" : "text-gray-400"
                    }`}
                  />
                </div>
                <input
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg transition-colors ${
                    isDarkMode
                      ? "bg-gray-900/50 border-gray-600 text-white placeholder-gray-500"
                      : "bg-white/50 border-gray-300 text-gray-900 placeholder-gray-400"
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="••••••••"
                />
              </div>
              <p
                className={`mt-2 text-sm ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                Password must be at least 6 characters long
              </p>
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 rounded-lg text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-0.5"
            >
              Sign Up
            </button>
          </form>
          <p
            className={`mt-6 text-center text-sm ${
              isDarkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            Already have an account?{" "}
            <button
              onClick={() => navigate("/signin")}
              className="font-medium text-blue-500 hover:text-blue-600 transition-colors"
            >
              Sign In
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}
