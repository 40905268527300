import React from "react";

import { useTheme } from "../context/ThemeContext";

import { useNavigate } from "react-router-dom";

import { ArrowLeft, Bug, Lightbulb, Rocket, Trophy, Crown, Star, ArrowRight } from "lucide-react";

import Navbar from "../components/Navbar";



interface Contributor {

  name: string;

  role: string;

  company: string;

  contribution: string;

  image: string;

}



const contributors = {

  bugBusters: [

    {

      name: "Sarah Chen",

      role: "QA Engineer",

      company: "TechCorp",

      contribution: "Identified and helped fix critical security vulnerabilities in authentication flow",

      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",

    },

    {

      name: "David Kumar",

      role: "Security Analyst",

      company: "SecureNet",

      contribution: "Discovered and patched potential data exposure vulnerabilities",

      image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",

    },

    {

      name: "Emily Rodriguez",

      role: "Test Engineer",

      company: "QualityFirst",

      contribution: "Implemented comprehensive test suite reducing bug escape rate by 40%",

      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",

    },

  ],

  trailblazers: [

    {

      name: "Michael Rodriguez",

      role: "Solutions Architect",

      company: "InnovateX",

      contribution: "First enterprise adoption and valuable platform feedback",

      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",

    },

    {

      name: "Lisa Wang",

      role: "Technical Lead",

      company: "FutureStack",

      contribution: "Pioneered integration with major cloud platforms",

      image: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f",

    },

    {

      name: "James Wilson",

      role: "DevOps Engineer",

      company: "CloudScale",

      contribution: "Created automated deployment templates for the community",

      image: "https://images.unsplash.com/photo-1463453091185-61582044d556",

    },

  ],

  ideaMavericks: [

    {

      name: "Priya Patel",

      role: "Product Manager",

      company: "FutureScale",

      contribution: "Proposed AI-powered documentation features that shaped our roadmap",

      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",

    },

    {

      name: "Alex Thompson",

      role: "Innovation Director",

      company: "TechForward",

      contribution: "Conceptualized the multi-modal documentation approach",

      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e",

    },

    {

      name: "Sophie Chen",

      role: "UX Researcher",

      company: "DesignHub",

      contribution: "Revolutionized our user interface with community-driven insights",

      image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb",

    },

  ],

};



function HallOfFame() {

  const { isDarkMode } = useTheme();

  const navigate = useNavigate();



  const ContributorCard = ({ contributor, type }: { contributor: Contributor; type: string }) => (

    <div

      className={`${

        isDarkMode ? "bg-gray-800/50" : "bg-white"

      } rounded-xl shadow-lg p-6 transform hover:-translate-y-2 transition-all duration-300 border ${

        isDarkMode ? "border-gray-700" : "border-gray-200"

      } hover:border-blue-500`}

    >

      <div className="flex items-center space-x-4">

        <img

          src={contributor.image}

          alt={contributor.name}

          className="w-16 h-16 rounded-full object-cover ring-2 ring-blue-500"

        />

        <div>

          <h3

            className={`text-xl font-semibold ${

              isDarkMode ? "text-white" : "text-gray-900"

            }`}

          >

            {contributor.name}

          </h3>

          <p

            className={`text-sm ${

              isDarkMode ? "text-gray-400" : "text-gray-500"

            }`}

          >

            {contributor.role} at {contributor.company}

          </p>

        </div>

      </div>

      <p className={`mt-4 ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>

        {contributor.contribution}

      </p>

    </div>

  );



  return (

    <div className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}>

      <Navbar />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">

        <button

          onClick={() => navigate("/")}

          className={`flex items-center mb-8 text-sm font-medium ${

            isDarkMode

              ? "text-gray-300 hover:text-white"

              : "text-gray-600 hover:text-gray-900"

          }`}

        >

          <ArrowLeft className="w-4 h-4 mr-2" />

          Back to Home

        </button>



        {/* Royal Header Section */}

        <div className="text-center mb-16">

          <div className="inline-flex items-center justify-center w-full">

            <Crown className={`w-12 h-12 ${isDarkMode ? "text-yellow-500" : "text-yellow-600"} mb-4`} />

          </div>

          <h1

            className={`text-5xl font-bold mb-4 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-transparent bg-clip-text

              ${isDarkMode ? "drop-shadow-lg" : "drop-shadow-md"}`}

          >

            Hall of Fame

          </h1>

          <p className={`text-xl ${isDarkMode ? "text-gray-300" : "text-gray-600"} 

            font-medium italic mt-4 max-w-2xl mx-auto`}

          >

            "The World's Gonna Know Your Name!"

          </p>

          <div className={`w-24 h-1 mx-auto mt-6 ${isDarkMode ? "bg-yellow-500" : "bg-yellow-600"} rounded-full`}></div>

        </div>



        <div className="grid lg:grid-cols-3 gap-8 mb-12">

          {/* Bug Busters Column */}

          <div className={`p-6 rounded-2xl ${isDarkMode ? "bg-gray-800/30" : "bg-white/90"} backdrop-blur-lg`}>

            <div className="flex flex-col mb-6">

              <div className="flex items-center mb-2">

                <Bug className="w-8 h-8 text-red-500 mr-3" />

                <h2 className={`text-2xl font-bold ${isDarkMode ? "text-white" : "text-gray-900"}`}>

                  Bug Busters

                </h2>

              </div>

              <p className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"} ml-11`}>

                For those who help by finding and reporting bugs

              </p>

              <a 

                href="https://forms.gle/3vQZk7MPBPeLHXQv7"

                target="_blank"

                rel="noopener noreferrer"

                className="ml-11 mt-3 inline-flex items-center text-sm text-red-500 hover:text-red-600 transition-colors group"

              >

                <span className="inline-flex items-center px-4 py-2 rounded-lg border-2 border-red-500 hover:bg-red-500 hover:text-white transition-all duration-300">

                  <Bug className="w-4 h-4 mr-2" />

                  Join the Bug Hunt

                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />

                </span>

              </a>

            </div>

            <div className="space-y-6">

              {contributors.bugBusters.map((contributor, index) => (

                <ContributorCard key={index} contributor={contributor} type="bugBuster" />

              ))}

            </div>

          </div>



          {/* Trailblazers Column */}

          <div className={`p-6 rounded-2xl ${isDarkMode ? "bg-gray-800/30" : "bg-white/90"} backdrop-blur-lg`}>

            <div className="flex flex-col mb-6">

              <div className="flex items-center mb-2">

                <Rocket className="w-8 h-8 text-blue-500 mr-3" />

                <h2 className={`text-2xl font-bold ${isDarkMode ? "text-white" : "text-gray-900"}`}>

                  Trailblazers

                </h2>

              </div>

              <p className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"} ml-11`}>

                For users who promote Unsloth and help it gain traction

              </p>

              <a 

                href="https://forms.gle/3vQZk7MPBPeLHXQv7"

                target="_blank"

                rel="noopener noreferrer"

                className="ml-11 mt-3 inline-flex items-center text-sm text-blue-500 hover:text-blue-600 transition-colors group"

              >

                <span className="inline-flex items-center px-4 py-2 rounded-lg border-2 border-blue-500 hover:bg-blue-500 hover:text-white transition-all duration-300">

                  <Rocket className="w-4 h-4 mr-2" />

                  Become a Pioneer

                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />

                </span>

              </a>

            </div>

            <div className="space-y-6">

              {contributors.trailblazers.map((contributor, index) => (

                <ContributorCard key={index} contributor={contributor} type="trailblazer" />

              ))}

            </div>

          </div>



          {/* Idea Mavericks Column */}

          <div className={`p-6 rounded-2xl ${isDarkMode ? "bg-gray-800/30" : "bg-white/90"} backdrop-blur-lg`}>

            <div className="flex flex-col mb-6">

              <div className="flex items-center mb-2">

                <Lightbulb className="w-8 h-8 text-yellow-500 mr-3" />

                <h2 className={`text-2xl font-bold ${isDarkMode ? "text-white" : "text-gray-900"}`}>

                  Idea Mavericks

                </h2>

              </div>

              <p className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"} ml-11`}>

                For users who contribute with innovative ideas and shape Unsloth's future

              </p>

              <a 

                href="https://forms.gle/3vQZk7MPBPeLHXQv7"

                target="_blank"

                rel="noopener noreferrer"

                className="ml-11 mt-3 inline-flex items-center text-sm text-yellow-500 hover:text-yellow-600 transition-colors group"

              >

                <span className="inline-flex items-center px-4 py-2 rounded-lg border-2 border-yellow-500 hover:bg-yellow-500 hover:text-white transition-all duration-300">

                  <Lightbulb className="w-4 h-4 mr-2" />

                  Share Your Vision

                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />

                </span>

              </a>

            </div>

            <div className="space-y-6">

              {contributors.ideaMavericks.map((contributor, index) => (

                <ContributorCard key={index} contributor={contributor} type="ideaMaverick" />

              ))}

            </div>

          </div>

        </div>

      </div>

    </div>

  );

}



export default HallOfFame;


