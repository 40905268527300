import React, { useState } from "react";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Calendar, Clock, User, X } from "lucide-react";
import Navbar from "../components/Navbar";

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  content: string; // Full content of the blog post
  author: string;
  date: string;
  readTime: string;
  image: string;
  category: string;
}

const blogPosts: BlogPost[] = [
  {
    id: "1",
    title: "The Future of AI-Powered Documentation",
    excerpt: "Explore how artificial intelligence is revolutionizing the way we create and manage technical documentation...",
    content: `Artificial intelligence is transforming the landscape of technical documentation, making it more efficient and accurate than ever before. 

    At Unsloth, we're leveraging cutting-edge AI technology to automate and enhance the documentation process. Our platform uses advanced natural language processing to understand context, generate comprehensive documentation, and maintain consistency across all your technical documents.

    Key benefits of AI-powered documentation:
    • Automated first drafts that save hours of work
    • Intelligent suggestions for improvements
    • Consistent terminology and formatting
    • Real-time collaboration with AI assistance
    • Smart templates that learn from your team's style

    The future of documentation is here, and it's powered by AI. Join us on this exciting journey as we continue to innovate and improve the way teams create and maintain their documentation.`,
    author: "Naveen",
    date: "March 20, 2024",
    readTime: "5 min read",
    image: "https://images.unsplash.com/photo-1677442136019-21780ecad995",
    category: "Technology",
  },
  // Add more blog posts as needed
];

function Blog() {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);

  const handlePostClick = (post: BlogPost) => {
    setSelectedPost(post);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate("/")}
          className={`flex items-center mb-8 text-sm font-medium ${
            isDarkMode
              ? "text-gray-300 hover:text-white"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </button>

        <h1 className={`text-4xl font-bold mb-12 ${isDarkMode ? "text-white" : "text-gray-900"}`}>
          Unsloth Blog
        </h1>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <article
              key={post.id}
              onClick={() => handlePostClick(post)}
              className={`${
                isDarkMode ? "bg-gray-800" : "bg-white"
              } rounded-lg shadow-lg overflow-hidden transform hover:-translate-y-1 transition-all duration-300 cursor-pointer`}
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      isDarkMode
                        ? "bg-blue-900/50 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    {post.category}
                  </span>
                </div>
                <h2
                  className={`text-xl font-bold mb-2 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {post.title}
                </h2>
                <p className={`mb-4 ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
                  {post.excerpt}
                </p>
                <div
                  className={`flex items-center text-sm ${
                    isDarkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  <User className="w-4 h-4 mr-1" />
                  <span className="mr-4">{post.author}</span>
                  <Calendar className="w-4 h-4 mr-1" />
                  <span className="mr-4">{post.date}</span>
                  <Clock className="w-4 h-4 mr-1" />
                  <span>{post.readTime}</span>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* Blog Post Modal */}
        {selectedPost && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div
              className={`${
                isDarkMode ? "bg-gray-800" : "bg-white"
              } rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto`}
            >
              <div className="relative">
                <img
                  src={selectedPost.image}
                  alt={selectedPost.title}
                  className="w-full h-64 object-cover rounded-t-lg"
                />
                <button
                  onClick={() => setSelectedPost(null)}
                  className="absolute top-4 right-4 p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70 transition-all"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="p-8">
                <div className="mb-6">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      isDarkMode
                        ? "bg-blue-900/50 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    {selectedPost.category}
                  </span>
                </div>
                <h2
                  className={`text-3xl font-bold mb-4 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {selectedPost.title}
                </h2>
                <div
                  className={`flex items-center text-sm ${
                    isDarkMode ? "text-gray-400" : "text-gray-500"
                  } mb-6`}
                >
                  <User className="w-4 h-4 mr-1" />
                  <span className="mr-4">{selectedPost.author}</span>
                  <Calendar className="w-4 h-4 mr-1" />
                  <span className="mr-4">{selectedPost.date}</span>
                  <Clock className="w-4 h-4 mr-1" />
                  <span>{selectedPost.readTime}</span>
                </div>
                <div
                  className={`prose ${
                    isDarkMode ? "prose-invert" : ""
                  } max-w-none ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}
                >
                  {selectedPost.content.split('\n').map((paragraph, index) => (
                    <p key={index} className="mb-4">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
