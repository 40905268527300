import React from "react";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Navbar from "../components/Navbar";

const Documentation: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate("/")}
          className={`flex items-center mb-8 text-sm font-medium ${
            isDarkMode
              ? "text-gray-300 hover:text-white"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </button>

        <div className="prose dark:prose-invert max-w-none">
          <h1
            className={`text-4xl font-bold mb-8 ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Documentation
          </h1>

          <section className="mb-12">
            <h2
              className={`text-2xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Getting Started
            </h2>
            <p
              className={`mb-4 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              Unsloth is an AI-powered documentation generator that helps you
              create various types of documents quickly and efficiently. Here's
              how to get started:
            </p>
            <ol
              className={`list-decimal pl-6 mb-6 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              <li className="mb-2">
                Sign up for an account or sign in if you already have one
              </li>
              <li className="mb-2">Navigate to the dashboard</li>
              <li className="mb-2">Select your desired document type</li>
              <li className="mb-2">Enter your project requirements</li>
              <li className="mb-2">
                Click "Generate Document" to create your documentation
              </li>
            </ol>
          </section>

          <section className="mb-12">
            <h2
              className={`text-2xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Document Types
            </h2>
            <h3
              className={`text-xl font-semibold mb-3 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Word Documents
            </h3>
            <ul
              className={`list-disc pl-6 mb-6 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              <li className="mb-2">
                <strong>Generic SDD:</strong> A comprehensive System Design
                Document template suitable for any software project
              </li>
              <li className="mb-2">
                <strong>Power App SDD:</strong> Specifically tailored for Power
                Apps projects, including UI/UX specifications
              </li>
            </ul>

            <h3
              className={`text-xl font-semibold mb-3 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Excel Documents
            </h3>
            <ul
              className={`list-disc pl-6 mb-6 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              <li className="mb-2">
                <strong>Test Case Document:</strong> Detailed test scenarios and
                cases for your application
              </li>
            </ul>

            <h3
              className={`text-xl font-semibold mb-3 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              PowerPoint Presentations
            </h3>
            <ul
              className={`list-disc pl-6 mb-6 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              <li className="mb-2">
                <strong>Weekly Presentation:</strong> Status updates and
                progress reports
              </li>
              <li className="mb-2">
                <strong>Product Presentation:</strong> Showcase your product
                features and benefits
              </li>
            </ul>
          </section>

          <section className="mb-12">
            <h2
              className={`text-2xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Best Practices
            </h2>
            <ul
              className={`list-disc pl-6 mb-6 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              <li className="mb-2">Be specific in your project requirements</li>
              <li className="mb-2">Review and customize generated content</li>
              <li className="mb-2">Save your documents for future reference</li>
              <li className="mb-2">
                Use the voice input feature for quick entry
              </li>
              <li className="mb-2">
                Check the generated documents before sharing
              </li>
            </ul>
          </section>

          <section>
            <h2
              className={`text-2xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Need Help?
            </h2>
            <p
              className={`mb-4 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              If you need assistance or have questions, please reach out to our
              support team at{" "}
              <a
                href="mailto:naveen.aifanatic@gmail.com"
                className="text-blue-500 hover:text-blue-600"
              >
                naveen.aifanatic@gmail.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
