import React, { useState, useRef, useEffect } from "react";
import {
  Mic,
  FileText,
  Settings,
  User,
  LogOut,
  Mail,
  StopCircle,
  ChevronDown,
  ChevronUp,
  Chrome,
} from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
import Confetti from "react-confetti";
import PageLayout from "../components/PageLayout";
import { useAnalytics } from "../context/AnalyticsContext";
import { useNavigate } from "react-router-dom";
import aiMagicGif from "../assets/ai-magic.gif";
import aiMagic2Gif from "../assets/ai-magic2.gif";
import aiMagic3Gif from "../assets/ai-magic3.gif";
// Add these type definitions at the top of the file
interface SpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  start(): void;
  stop(): void;
  onresult: (event: SpeechRecognitionEvent) => void;
  onerror: (event: SpeechRecognitionErrorEvent) => void;
}
interface SpeechRecognitionEvent {
  resultIndex: number;
  results: {
    length: number;
    [index: number]: {
      isFinal: boolean;
      [index: number]: {
        transcript: string;
      };
    };
  };
}
interface SpeechRecognitionErrorEvent extends Event {
  error: string;
}
// Constants should be outside component
const documentTypes = {
  Word: [
    { name: "Generic SDD", disabled: false },
    { name: "Power App SDD", disabled: false },
    { name: "Process Design Document", disabled: true },
    { name: "UiPath SDD", disabled: true },
    { name: "Statement of Work", disabled: true },
    { name: "Technical KT", disabled: false },
  ],
  Excel: [
    { name: "Test Case Document", disabled: false },
    { name: "Automation Support Guide", disabled: true },
  ],
  PowerPoint: [
    { name: "Weekly Presentation", disabled: false },
    { name: "Product Presentation", disabled: false },
  ],
};
// Update the documentTimesSaved constant with new time values
const documentTimesSaved = {
  "Process Design Document": 6,
  "Power App SDD": 5,
  "UiPath SDD": 4,
  "Statement of Work": 5,
  "Generic SDD": 4.5,
  "Technical KT": 3.5,
  "Test Case Document": 3,
  "Automation Support Guide": 4,
  "Weekly Presentation": 4,
  "Generic Presentation": 3,
  "Product Presentation": 3,
};
// Update the samplePrompts type definition
interface PromptTemplate {
  display: string;
  template: string;
}
interface SamplePrompts {
  [key: string]: PromptTemplate;
}
const samplePrompts: SamplePrompts = {
  "Power App SDD": {
    display:
      "Example: 'A timesheet management system that allows employees to log daily hours, submit for approval, and generate reports. The system includes manager approval workflow and integrates with existing HR systems.'",
    template:
      "[Application Purpose] that enables users to [Core Functionality]. The system includes [Key Features] and integrates with [Integration Points].",
  },
  "Generic SDD": {
    display:
      "Example: 'An inventory management system that tracks stock levels in real-time, generates low-stock alerts, and provides comprehensive reporting capabilities. Includes barcode scanning and supplier management features.'",
    template:
      "[System Description] that [Primary Function]. The system provides [Key Features] and supports [Additional Capabilities].",
  },
  "Technical KT": {
    display:
      "Example: 'Authentication service built with OAuth 2.0, handling user authentication and authorization. Includes JWT token management, role-based access control, and integrates with Azure AD.'",
    template:
      "[Component/Service Name] built with [Technology Stack], handling [Core Functions]. Includes [Key Features] and integrates with [Integration Points].",
  },
  "Test Case Document": {
    display:
      "Example: 'Login functionality with email and password authentication, social login options, and multi-factor authentication. Includes password reset flow and session management.'",
    template:
      "[Feature/Module Name] with [Core Functions]. Includes [Key Features] and handles [Edge Cases/Special Scenarios].",
  },
  "Weekly Presentation": {
    display:
      "Example: 'Completed user authentication module and dashboard features. Achieved 95% test coverage. Risks include potential API integration delays. Next sprint focuses on reporting features.'",
    template:
      "Completed [Deliverables]. Achieved [Metrics/Results]. Risks include [Risk Factors]. Next sprint focuses on [Upcoming Tasks].",
  },
  "Product Presentation": {
    display:
      "Example: 'AI-powered documentation system with smart content generation, automated test case creation, and seamless third-party integrations. Reduces documentation time by 60%.'",
    template:
      "[Product Name] with [Key Features]. Provides [Benefits] and enables [Capabilities]. Impact includes [Metrics/Results].",
  },
};
// Update this constant to use your Worker URL
const CLOUDFLARE_API_URL = "https://unsloth.naveen-aifanatic.workers.dev";
const API_KEY = import.meta.env.VITE_CLOUDFLARE_API_KEY;
// Update the interface for the API request log to include project description and recipient emails
interface APIRequestLog {
  user_email: string;
  document_type: string;
  report_type: string;
  status: string;
  generation_time: number;
  timestamp: string;
  project_description: string;
  recipient_emails: string;
}
// Update the logAPIRequest function
const logAPIRequest = async (requestLog: APIRequestLog) => {
  try {
    if (!API_KEY) {
      console.error(
        "API key is missing. Please check your environment variables."
      );
      throw new Error("API key is not configured");
    }
    // Validate data before sending
    const requestBody = {
      sql: `INSERT INTO api_requests (
        user_email, 
        document_type, 
        report_type, 
        status, 
        generation_time, 
        timestamp, 
        project_description,
        recipient_emails
      ) VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
      params: [
        requestLog.user_email,
        requestLog.document_type,
        requestLog.report_type,
        requestLog.status,
        requestLog.generation_time,
        requestLog.timestamp,
        requestLog.project_description,
        requestLog.recipient_emails,
      ],
    };
    // Log the request data
    console.log("Sending request:", {
      url: CLOUDFLARE_API_URL,
      body: requestBody,
    });
    const response = await fetch(CLOUDFLARE_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify(requestBody),
    });
    const responseData = await response.json();
    if (!response.ok) {
      console.error("Failed to log API request:", {
        status: response.status,
        statusText: response.statusText,
        data: responseData,
        headers: Object.fromEntries(response.headers.entries()),
      });
      throw new Error(
        `API request failed: ${responseData.error || "Unknown error"}`
      );
    }
    console.log("Successfully logged API request:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error logging API request:", {
      error,
      details:
        error instanceof Error
          ? {
              name: error.name,
              message: error.message,
              stack: error.stack,
            }
          : "Unknown error type",
    });
    // Don't throw the error, just log it
    return null;
  }
};
// Move these constants outside the component
const EMAIL_DOMAINS = [
  "@gmail.com",
  "@ashlingpartners.com",
  "@yahoo.com",
  "@outlook.com",
];
export default function Dashboard() {
  console.log("Environment variables:", {
    hasApiKey: !!API_KEY,
  });
  console.log("Dashboard component rendering");
  // All state declarations
  const [status, setStatus] = useState<string>("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [convertedText, setConvertedText] = useState("");
  const [selectedFileType, setSelectedFileType] = useState<string>("Word");
  const [selectedReportType, setSelectedReportType] = useState<string>(
    documentTypes.Word[0].name
  );
  const [recipients, setRecipients] = useState("");
  const { isDarkMode } = useTheme();
  const { user, logout } = useAuth();
  const [birthday, setBirthday] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [peopleTeamUpdate, setPeopleTeamUpdate] = useState("");
  const [showOptionalUpdates, setShowOptionalUpdates] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showExtensionMessage, setShowExtensionMessage] = useState(false);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const [interimTranscript, setInterimTranscript] = useState("");

  // Add the new states for email suggestions
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const recipientInputRef = useRef<HTMLInputElement>(null);
  // Speech recognition setup
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  // Email suggestion handlers
  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRecipients(value);
    // Find the current email being typed
    const emails = value.split(/[;,\s]+/);
    const currentEmail = emails[emails.length - 1].trim();
    if (currentEmail.includes("@")) {
      // Don't show suggestions if the email already has a complete domain
      setSuggestions([]);
      return;
    }
    if (currentEmail.length > 0) {
      // Generate suggestions based on the current input
      const newSuggestions = EMAIL_DOMAINS.map(
        (domain) => `${currentEmail}${domain}`
      );
      setSuggestions(newSuggestions);
      setActiveSuggestion(-1);
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (suggestion: string) => {
    const emails = recipients.split(/[;,\s]+/);
    emails[emails.length - 1] = suggestion;
    setRecipients(emails.join("; "));
    setSuggestions([]);
    recipientInputRef.current?.focus();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (suggestions.length === 0) return;
    // Handle arrow keys and enter
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveSuggestion((prev) =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (activeSuggestion >= 0) {
          handleSuggestionClick(suggestions[activeSuggestion]);
        }
        break;
      case "Escape":
        setSuggestions([]);
        setActiveSuggestion(-1);
        break;
    }
  };
  // Add this function inside the Dashboard component, after the state declarations and before the email handlers
  const toggleRecording = () => {
    if (!isRecording) {
      initializeSpeechRecognition();
      if (recognitionRef.current) {
        recognitionRef.current.start();
        setIsRecording(true);
      } else {
        alert("Speech recognition is not supported in your browser");
      }
    } else {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsRecording(false);
    }
  };
  // Add this function as well if it's missing
  const initializeSpeechRecognition = () => {
    if (!recognitionRef.current) {
      const SpeechRecognition = (window.SpeechRecognition ||
        window.webkitSpeechRecognition) as typeof window.SpeechRecognition;
      if (SpeechRecognition) {
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.onresult = (event: SpeechRecognitionEvent) => {
          let interimTranscript = "";
          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
              // Append new text to existing text
              setConvertedText((prev) => {
                const newText = prev ? `${prev} ${transcript}` : transcript;
                return newText.trim();
              });
            } else {
              interimTranscript = transcript;
              setInterimTranscript(interimTranscript);
            }
          }
        };
        recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
          console.error("Speech recognition error:", event.error);
          setIsRecording(false);
        };
        recognitionRef.current = recognition;
      }
    }
  };
  // Replace the existing handleGenerate function with this new generateDocument function
  const generateDocument = async () => {
    if (!API_KEY) {
      setStatus("Error: API key is not configured. Please contact support.");
      return;
    }
    // Add start time tracking
    const startTime = Date.now();
    // Reset states
    setError(null);
    setIsLoading(true);
    setLoadingProgress(0);
    // Validate mandatory fields
    if (!convertedText.trim() || !recipients.trim()) {
      setStatus("Error: Both content and email addresses are required.");
      setIsLoading(false);
      return;
    }
    setStatus("Generating document...");
    try {
      // Track document generation attempt
      trackEvent("generate_document", "document", selectedFileType);
      // Simulate progress while API is working
      const progressInterval = setInterval(() => {
        setLoadingProgress((prev) => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 500);
      // Convert comma-separated emails to semicolon-separated
      const recipientEmails = recipients
        .split(",")
        .map((email) => email.trim())
        .join(";");
      let endpoint = "https://unsloth-ic24.azurewebsites.net/api/";
      let requestBody: any = {};
      // Configure endpoint and request body based on document type
      switch (selectedReportType) {
        case "Power App SDD":
          endpoint += "Http_PowerAppSDD";
          requestBody = {
            projectDescription: convertedText,
            recipientEmails,
          };
          break;
        case "Technical KT":
          endpoint += "Http_TechnicalKT";
          requestBody = {
            projectDescription: convertedText.trim(),
            recipientEmails: recipients
              .split(",")
              .map((email) => email.trim())
              .join(";"),
          };
          break;
        case "Generic SDD":
          endpoint += "Http_GenericSDD";
          requestBody = {
            projectDescription: convertedText,
            recipientEmails,
          };
          break;
        case "Product Presentation":
          endpoint += "Http_ProductPPT";
          requestBody = {
            projectDescription: convertedText,
            recipientEmails,
          };
          break;
        case "Weekly Presentation":
        case "Generic Presentation":
          endpoint += "Http_PPT";
          requestBody = {
            emails: recipientEmails,
            birthday,
            anniversary,
            date: new Date().toISOString().split("T")[0],
            peopleTeam: peopleTeamUpdate,
            client: convertedText,
          };
          break;
        case "Test Case Document":
          endpoint += "Http_TestCaseDoc";
          requestBody = {
            projectDescription: convertedText,
            recipientEmails,
          };
          break;
        default:
          setStatus("Error: Selected document type is not supported yet");
          return;
      }
      endpoint +=
        "?code=JZuvK6rFJI8f9ho1KTC46QcGYtVHKa_EhRFj4BClmWyLAzFu4g6Rig%3D%3D";
      console.log("Endpoint:", endpoint);
      console.log("Request Body:", JSON.stringify(requestBody, null, 2));
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      // Add response logging
      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error Response:", errorText);
      }
      // Clear the interval and set progress to 100% when API responds
      clearInterval(progressInterval);
      setLoadingProgress(100);
      if (response.ok) {
        // Calculate generation time - round to whole seconds
        const generationTime = Math.round(
          (Date.now() - startTime) / 1000
        ).toString(); // Remove decimals
        // Track successful generation
        trackEvent(
          "document_generated",
          "document",
          selectedReportType,
          documentTimesSaved[
            selectedReportType as keyof typeof documentTimesSaved
          ]
        );
        // Save document to localStorage
        const newDocument = {
          id: Date.now(),
          name: `${selectedReportType}.${selectedFileType.toLowerCase()}`,
          type: selectedFileType,
          reportType: selectedReportType,
          date: new Date().toISOString(),
          description: convertedText,
          emails: recipients,
          generationTime: generationTime, // Will now be a whole number
        };
        const existingDocs = JSON.parse(
          localStorage.getItem("documents") || "[]"
        );
        const updatedDocs = [newDocument, ...existingDocs];
        localStorage.setItem("documents", JSON.stringify(updatedDocs));
        // Clear form
        setConvertedText("");
        setRecipients("");
        setBirthday("");
        setAnniversary("");
        setPeopleTeamUpdate("");
        // Show success message with hours saved
        const hoursSaved =
          documentTimesSaved[
            selectedReportType as keyof typeof documentTimesSaved
          ] || 4;
        setStatus(
          `🎉 Congratulations! You've saved ${hoursSaved} hours of work!`
        );
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
        // Log the successful API request
        await logAPIRequest({
          user_email: user?.email || "anonymous",
          document_type: selectedFileType,
          report_type: selectedReportType,
          status: "success",
          generation_time: (Date.now() - startTime) / 1000,
          timestamp: new Date().toISOString(),
          project_description: convertedText,
          recipient_emails: recipients,
        });
      } else {
        const errorData = await response.json();
        setStatus(
          `Error: ${errorData.message || "Failed to generate document"}`
        );
        // Log the failed API request
        await logAPIRequest({
          user_email: user?.email || "anonymous",
          document_type: selectedFileType,
          report_type: selectedReportType,
          status: "error",
          generation_time: (Date.now() - startTime) / 1000,
          timestamp: new Date().toISOString(),
          project_description: convertedText,
          recipient_emails: recipients,
        });
      }
    } catch (error) {
      console.error("Full error:", error);
      // Track errors
      trackEvent(
        "document_error",
        "error",
        error instanceof Error ? error.message : "Unknown error"
      );
      console.error("Error generating document:", error);
      setStatus(
        `Error: ${
          error instanceof Error ? error.message : "Failed to generate document"
        }`
      );
      // Log the failed API request
      await logAPIRequest({
        user_email: user?.email || "anonymous",
        document_type: selectedFileType,
        report_type: selectedReportType,
        status: "error",
        generation_time: (Date.now() - startTime) / 1000,
        timestamp: new Date().toISOString(),
        project_description: convertedText,
        recipient_emails: recipients,
      });
    } finally {
      setIsLoading(false);
      setLoadingProgress(0);
    }
  };
  // Add this useEffect hook to reset the loading progress when isLoading becomes false
  useEffect(() => {
    if (!isLoading) {
      setLoadingProgress(0);
    }
  }, [isLoading]);
  console.log("State initialized");
  console.log("Before return");
  return (
    <PageLayout title="Dashboard" disableLogoLink={true}>
      {showConfetti && <Confetti />}
      <div className="max-w-4xl mx-auto space-y-8">
        {/* Document and Report Type Selection */}
        <div
          className={`${
            isDarkMode ? "bg-gray-800 border border-gray-700" : "bg-white"
          } rounded-xl shadow-sm p-6`}
        >
          <div className="grid grid-cols-2 gap-6">
            {/* Document Type Dropdown */}
            <div>
              <label
                className={`block text-lg font-medium mb-2 select-none ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Document Type
              </label>
              <select
                value={selectedFileType}
                onChange={(e) => {
                  setSelectedFileType(e.target.value);
                  setSelectedReportType(
                    documentTypes[
                      e.target.value as keyof typeof documentTypes
                    ][0].name
                  );
                }}
                className={`block w-full rounded-lg transition-colors ${
                  isDarkMode
                    ? "bg-gray-900 border-gray-700 text-white"
                    : "border-gray-300 bg-white"
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                {Object.keys(documentTypes).map((fileType) => (
                  <option key={fileType} value={fileType}>
                    {fileType}
                  </option>
                ))}
              </select>
            </div>
            {/* Report Type Dropdown */}
            <div>
              <label
                className={`block text-lg font-medium mb-2 select-none ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Report Type
              </label>
              <select
                value={selectedReportType}
                onChange={(e) => setSelectedReportType(e.target.value)}
                className={`block w-full rounded-lg transition-colors ${
                  isDarkMode
                    ? "bg-gray-900 border-gray-700 text-white"
                    : "border-gray-300 bg-white"
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                {documentTypes[
                  selectedFileType as keyof typeof documentTypes
                ].map((type) => (
                  <option
                    key={type.name}
                    value={type.name}
                    disabled={type.disabled}
                  >
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* Voice Recording and Text Input */}
        <div
          className={`${
            isDarkMode ? "bg-gray-800 border border-gray-700" : "bg-white"
          } rounded-xl shadow-sm p-6`}
        >
          <div className="flex items-center justify-between mb-6">
            <div className="flex-1">
              <h3
                className={`text-lg font-medium select-none ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                {selectedFileType === "PowerPoint"
                  ? "Client Update"
                  : "Project Description"}
              </h3>
              <p
                className={`text-sm ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                {isRecording
                  ? "Recording in progress..."
                  : "Click the microphone to start recording"}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <button
                onClick={toggleRecording}
                className={`p-4 rounded-full transition-all transform hover:scale-105 ${
                  isRecording
                    ? "bg-red-500/10 text-red-500"
                    : isDarkMode
                    ? "bg-blue-500/10 text-blue-400"
                    : "bg-blue-50 text-blue-600"
                }`}
              >
                {isRecording ? (
                  <StopCircle className="h-8 w-8" />
                ) : (
                  <Mic className="h-8 w-8" />
                )}
              </button>
              <span
                className={`text-xs mt-2 ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                Voice Input
              </span>
            </div>
          </div>
          <div className="relative mb-2">
            <textarea
              value={convertedText}
              onChange={(e) => setConvertedText(e.target.value)}
              className={`w-full h-48 p-4 rounded-lg transition-colors ${
                isDarkMode
                  ? "bg-gray-900 border-gray-700 text-white placeholder-gray-500"
                  : "bg-white border-gray-200 placeholder-gray-400"
              } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              placeholder={
                samplePrompts[selectedReportType]?.display ||
                "Start speaking or type your documentation content here..."
              }
            />
          </div>
          <div className="flex justify-end mt-2">
            <button
              onClick={() => {
                setConvertedText(
                  samplePrompts[selectedReportType]?.template || ""
                );
              }}
              className={`px-3 py-1.5 text-sm rounded-md ${
                isDarkMode
                  ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              } transition-colors`}
            >
              Wanna try a template?
            </button>
          </div>
        </div>
        {/* Error Message */}
        {error && (
          <div
            className={`${isDarkMode ? "bg-red-900/50" : "bg-red-50"} border ${
              isDarkMode ? "border-red-800" : "border-red-200"
            } rounded-lg p-4 mb-6`}
          >
            <p className="text-red-600 dark:text-red-400">{error}</p>
          </div>
        )}
        {/* Optional Updates for PPT - Now Collapsible */}
        {selectedFileType === "PowerPoint" &&
          selectedReportType !== "Product Presentation" && (
            <div
              className={`${
                isDarkMode ? "bg-gray-800 border border-gray-700" : "bg-white"
              } rounded-xl shadow-sm p-6`}
            >
              <button
                onClick={() => setShowOptionalUpdates(!showOptionalUpdates)}
                className="w-full flex items-center justify-between"
              >
                <div className="flex items-center justify-between w-full">
                  <label
                    className={`text-lg font-medium select-none ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    Optional Updates
                  </label>
                  {showOptionalUpdates ? (
                    <ChevronUp
                      className={`h-5 w-5 ${
                        isDarkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    />
                  ) : (
                    <ChevronDown
                      className={`h-5 w-5 ${
                        isDarkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    />
                  )}
                </div>
              </button>
              {showOptionalUpdates && (
                <div className="space-y-4 mt-4">
                  <div>
                    <label
                      className={`block text-sm font-medium mb-1 select-none ${
                        isDarkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      Birthdays
                    </label>
                    <input
                      type="text"
                      value={birthday}
                      onChange={(e) => setBirthday(e.target.value)}
                      placeholder="John Doe (1st), Jane Smith (5th), Mike Johnson (15th)"
                      className={`block w-full px-4 py-3 rounded-lg transition-colors ${
                        isDarkMode
                          ? "bg-gray-900 border-gray-700 text-white placeholder-gray-500"
                          : "border-gray-200 placeholder-gray-400"
                      } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                  </div>
                  <div>
                    <label
                      className={`block text-sm font-medium mb-1 select-none ${
                        isDarkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      Work Anniversaries
                    </label>
                    <input
                      type="text"
                      value={anniversary}
                      onChange={(e) => setAnniversary(e.target.value)}
                      placeholder="John Doe (2 years), Jane Smith (5 years), Mike Johnson (3 years)"
                      className={`block w-full px-4 py-3 rounded-lg transition-colors ${
                        isDarkMode
                          ? "bg-gray-900 border-gray-700 text-white placeholder-gray-500"
                          : "border-gray-200 placeholder-gray-400"
                      } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                  </div>
                  <div>
                    <label
                      className={`block text-sm font-medium mb-1 select-none ${
                        isDarkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      People Team Updates
                    </label>
                    <input
                      type="text"
                      value={peopleTeamUpdate}
                      onChange={(e) => setPeopleTeamUpdate(e.target.value)}
                      placeholder="People Team Updates"
                      className={`block w-full px-4 py-3 rounded-lg transition-colors ${
                        isDarkMode
                          ? "bg-gray-900 border-gray-700 text-white placeholder-gray-500"
                          : "border-gray-200 placeholder-gray-400"
                      } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        {/* Recipients Input */}
        <div
          className={`${
            isDarkMode ? "bg-gray-800 border border-gray-700" : "bg-white"
          } rounded-xl shadow-sm p-6`}
        >
          <div className="flex items-center justify-between mb-4">
            <label
              className={`text-lg font-medium select-none ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Recipients
            </label>
            <div
              className={`text-sm ${
                isDarkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Add email addresses separated by semicolons
            </div>
          </div>
          <div className="relative rounded-lg shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail
                className={`h-5 w-5 ${
                  isDarkMode ? "text-gray-500" : "text-gray-400"
                }`}
              />
            </div>
            <input
              ref={recipientInputRef}
              type="text"
              value={recipients}
              onChange={handleEmailInput}
              onKeyDown={handleKeyDown}
              className={`block w-full pl-10 pr-3 py-3 rounded-lg transition-colors ${
                isDarkMode
                  ? "bg-gray-900 border-gray-700 text-white placeholder-gray-500"
                  : "border-gray-200 placeholder-gray-400"
              } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              placeholder="email1@example.com; email2@example.com"
            />
            {/* Suggestions dropdown */}
            {suggestions.length > 0 && (
              <div
                className={`absolute z-10 w-full mt-1 rounded-md shadow-lg ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } border ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}
              >
                <ul className="py-1">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={suggestion}
                      className={`px-3 py-2 cursor-pointer ${
                        index === activeSuggestion
                          ? isDarkMode
                            ? "bg-gray-700 text-white"
                            : "bg-blue-50 text-blue-700"
                          : isDarkMode
                          ? "text-gray-200 hover:bg-gray-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {/* Update the loading bar section */}
        {isLoading && (
          <div className="space-y-4">
            <div className="flex flex-col items-center justify-center space-y-3">
              <img
                src={
                  selectedFileType === "Word"
                    ? aiMagicGif
                    : selectedFileType === "Excel"
                    ? aiMagic2Gif
                    : aiMagic3Gif
                }
                alt="AI Magic"
                className="w-64 h-32 object-contain"
              />
              <p className="text-lg font-medium text-blue-600 dark:text-blue-400 animate-pulse">
                ✨ Unsloth AI is working its magic ✨
              </p>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${loadingProgress}%` }}
              ></div>
            </div>
          </div>
        )}
        {/* Status message */}
        {status && (
          <div
            className={`p-4 rounded-lg ${
              status.startsWith("Error")
                ? "bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300"
                : "bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300"
            }`}
          >
            {status}
          </div>
        )}
        <div className="flex justify-center pt-4">
          <button
            onClick={generateDocument}
            disabled={isLoading}
            className={`inline-flex items-center px-8 py-3 rounded-lg text-white 
              ${
                isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
              } 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
              shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-0.5`}
          >
            <FileText className="mr-2 h-5 w-5" />
            {isLoading ? "Generating..." : "Generate Document"}
          </button>
        </div>
      </div>
      {/* Chrome Extension Message */}
      {showExtensionMessage && (
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
        >
          <div
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md`}
          >
            <h3 className="text-lg font-semibold mb-2">Chrome Extension</h3>
            <p className="mb-4">
              For updates on the Chrome extension, please reach out to
              naveen.aifanatic@gmail.com
            </p>
            <button
              onClick={() => setShowExtensionMessage(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </PageLayout>
  );
}
