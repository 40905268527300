declare global {
  interface Window {
    gtag: (
      command: string,
      action: string,
      params?: Record<string, any>
    ) => void;
  }
}

export const useAnalytics = () => {
  const trackEvent = (
    action: string,
    category: string,
    label: string,
    value?: number
  ) => {
    if (window.gtag) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };

  const trackPageView = (path: string) => {
    if (window.gtag) {
      window.gtag("config", "G-WJK1Q3WWNP", {
        page_path: path,
      });
    }
  };

  return { trackEvent, trackPageView };
};
