import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png"; // Add your logo file here
import { useTheme } from "../context/ThemeContext";

function Navbar() {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  // Add this to your navigation items
  const navigation = [
    { name: "Home", href: "/" },
    { name: "Documentation", href: "/docs" },
    { name: "Testimonials", href: "/testimonials" },
    // ... other navigation items
  ];

  return (
    <nav
      className={`${
        isDarkMode
          ? "bg-gray-800 border-b border-gray-700"
          : "bg-white shadow-sm"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            {/* Replace Brain icon with custom logo */}
            <img src={logo} alt="Unsloth Logo" className="h-8 w-auto" />
            <span
              className={`ml-2 text-xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Unsloth
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate("/signin")}
              className={`px-4 py-2 text-sm font-medium ${
                isDarkMode
                  ? "text-gray-300 hover:text-white"
                  : "text-gray-700 hover:text-gray-900"
              }`}
            >
              Sign in
            </button>
            <button
              onClick={() => navigate("/signin")}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
