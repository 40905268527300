import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_apiKey,
  authDomain: import.meta.env.VITE_authDomain,
  projectId: import.meta.env.VITE_projectId,
  storageBucket: import.meta.env.VITE_storageBucket,
  messagingSenderId: import.meta.env.VITE_messagingSenderId,
  appId: import.meta.env.VITE_appId,
  measurementId: import.meta.env.VITE_measurementId,
};

// Initialize Firebase with error handling
let app;
try {
  app = initializeApp(firebaseConfig);
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error("Error initializing Firebase:", error);
  throw error;
}

// Initialize Analytics with better error handling
export let analytics = null;

const initializeAnalytics = async () => {
  try {
    // Check if analytics is supported in the current environment
    const supported = await isSupported();
    if (supported) {
      analytics = getAnalytics(app);
      console.log("Analytics initialized successfully");
      // Send a test event
      logEvent(analytics, "app_initialized", {
        timestamp: new Date().toISOString(),
      });
    } else {
      console.log("Analytics not supported in this environment");
    }
  } catch (error) {
    console.error("Error initializing Analytics:", error);
  }
};

initializeAnalytics();

export const auth = getAuth(app);
