import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Brain,
  FileText,
  GitFork,
  TestTube,
  Database,
  ArrowRight,
  Check,
  Sparkles,
  HelpCircle, // Change this from QuestionMarkCircle to HelpCircle
  Chrome,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import Navbar from "../components/Navbar";
import { useTheme } from "../context/ThemeContext";
const features = [
  {
    icon: FileText,
    title: "Process Design Documents",
    description: "Create comprehensive process documentation with ease",
  },
  {
    icon: GitFork,
    title: "Solution Architecture",
    description: "Generate detailed architectural diagrams automatically",
  },
  {
    icon: Brain,
    title: "Smart Documentation",
    description: "AI-powered documentation generation and insights",
  },
  {
    icon: TestTube,
    title: "Test Case Generation",
    description: "Automatically create test cases from requirements",
  },
  {
    icon: Database,
    title: "Data Modeling",
    description: "Create and visualize data models effortlessly",
  },
];
const pricingPlans = [
  {
    name: "Starter",
    price: "Free",
    description: "Perfect for trying out Unsloth",
    features: [
      "5 documents per month",
      "Basic documentation templates",
      "Export to PDF",
      "Email support",
    ],
  },
  {
    name: "Pro (Free in Beta)",
    price: (
      <>
        <span className="line-through text-gray-400">$29</span>{" "}
        <span className="text-green-500">$0</span>
      </>
    ),
    description: "For professional developers",
    features: [
      "Unlimited documents",
      "Advanced AI features",
      "Custom templates",
      "Priority support",
      "Team collaboration",
      "API access",
    ],
    popular: true,
  },
  {
    name: "Enterprise",
    price: "Custom",
    description: "For large organizations",
    features: [
      "Everything in Pro",
      "Custom integrations",
      "Dedicated support",
      "SLA guarantees",
      "Advanced security",
      "Custom AI training",
    ],
  },
];
// Add this interface for FAQ items
interface FAQItem {
  question: string;
  answer: string;
  isOpen?: boolean;
}
// Add this interface before the LandingPage function
interface Testimonial {
  name: string;
  role: string;
  company: string;
  image: string;
  content: string;
  rating: number;
}
function LandingPage() {
  console.log("LandingPage rendering");
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [showExtensionMessage, setShowExtensionMessage] = useState(false);
  // Define testimonials inside the component
  const testimonials: Testimonial[] = [
    {
      name: "Brian Barrett",
      role: "Managing Director, Business Services Client Principal",
      company: "Ashling Partners",
      image: "/testimonials/Brian.jpg",
      content:
        "Documentation can be a tedious and time-consuming endeavor. Thankfully, this solution streamlines the process by enabling you to submit a concise prompt and effortlessly obtain thorough documentation in your preferred format. Give it a try, and you'll be delighted with the results!",
      rating: 5,
    },
    {
      name: "Adam Voll",
      role: "Sr. Manager, Solutions Architecture",
      company: "Ashling Partners",
      image: "/testimonials/Adam.jpg",
      content:
        "Even in its initial form, UnSloth is already proving to be a gamechanger. One of the primary questions I'm asked by newly-onboarded team members is, 'do you have a good example of an existing SDD that I can use as reference for my first design?' With UnSloth, that same team member would be able to feed their requirements in plain text and immediately receive an output that aligns with best practices. Moreover, it removes bottlenecks around solution design in the project timeline. Using UnSloth, a BA or Developer who aspires to become a Solution Architect is now enabled to create detailed SDDs (or any other documentation) which they would formerly be unable to create without hands-on support. UnSloth truly will open doors for various career paths, and most certainly enable greater delivery efficiency without sacrificing quality. Huge fan, and can't wait to see where it goes!",
      rating: 5,
    },
    {
      name: "Swetha Gorugantu",
      role: "Senior Test Automation Engineer",
      company: "CSG Forte Payment Systems",
      image: "/testimonials/Swetha.jpeg",
      content:
        "Unsloth is intuitive and user-friendly, with a clean design that makes navigation effortless. As a Test Engineer, I create multiple test case documents for various requirements on a daily basis. Unsloth has made this easy for me by automating repetitive tasks and enhancing productivity. I was overwhelmed by the number of test scenarios it generated based on a single sentence of information that I have given. Great Initiative, Keep Going!!",
      rating: 5,
    },
  ];
  // Update the faqs state to handle collapsible functionality
  const [faqs, setFaqs] = useState<FAQItem[]>([
    {
      question: "What is Unsloth?",
      answer:
        "Unsloth is an AI-powered Multi modal documentation agent platform that helps teams create and maintain technical documentation efficiently.",
      isOpen: false,
    },
    {
      question: "How does it work?",
      answer:
        "Simply describe your project or requirements, and Unsloth will generate comprehensive documentation using advanced AI technology.",
      isOpen: false,
    },
    {
      question: "What types of documents can I create?",
      answer:
        "Currently, you can create PowerApp SDDs, Test Cases, and PowerPoint presentations. We're continuously adding support for more document types.",
      isOpen: false,
    },
    {
      question: "Is my data secure?",
      answer:
        "Yes, we take security seriously. All data is encrypted and stored securely. We never share your information with third parties.",
      isOpen: false,
    },
  ]);
  // Add toggle function for FAQ items
  const toggleFAQ = (index: number) => {
    setFaqs(
      faqs.map((faq, i) => ({
        ...faq,
        isOpen: i === index ? !faq.isOpen : false,
      }))
    );
  };
  return (
    <div
      className={`min-h-screen ${
        isDarkMode
          ? "bg-gray-900 text-white"
          : "bg-gradient-to-b from-gray-50 to-white"
      }`}
    >
      <Navbar />
      {/* Hero Section with Background Pattern */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 backdrop-blur-3xl" />
          <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-blue-500/5 to-transparent" />
        </div>
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="text-left space-y-8">
              <div>
                <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/10 text-blue-500 mb-4">
                  <Sparkles className="h-4 w-4 mr-2" />
                  <span className="text-sm font-medium">
                    AI-powered Multi Modal Agent
                  </span>
                </div>
                <h1 className="text-5xl font-bold leading-tight mb-6">
                  Documentation Made <br />
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                    Effortless
                  </span>
                </h1>
                <p
                  className={`text-xl ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  } max-w-xl`}
                >
                  Transform your project documentation workflow with AI-powered
                  agentic automation. From architecture diagrams to test cases,
                  Unsloth handles it all.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={() => navigate("/signin")}
                  className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all shadow-lg hover:shadow-xl"
                >
                  Get Started <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button
                  onClick={() => navigate("/signin")}
                  className={`inline-flex items-center px-6 py-3 text-lg font-medium rounded-lg transition-all
                    ${
                      isDarkMode
                        ? "bg-gray-800 text-white hover:bg-gray-700"
                        : "bg-white text-gray-700 hover:bg-gray-50 shadow-md hover:shadow-lg"
                    }`}
                >
                  View Demo
                </button>
                <button
                  onClick={() => setShowExtensionMessage(true)}
                  className={`inline-flex items-center px-6 py-3 text-lg font-medium rounded-lg transition-all
                    ${
                      isDarkMode
                        ? "bg-gray-800 text-white hover:bg-gray-700"
                        : "bg-white text-gray-700 hover:bg-gray-50 shadow-md hover:shadow-lg"
                    }`}
                >
                  <Chrome className="mr-2 h-5 w-5" />
                  Chrome Extension
                </button>
              </div>
            </div>
            <div className="hidden lg:block relative">
              <img
                src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80"
                alt="Dashboard Preview"
                className="rounded-2xl shadow-2xl"
              />
              <div className="absolute -bottom-6 -left-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 flex items-center gap-3">
                <div className="bg-blue-500 rounded-lg p-2">
                  <Brain className="h-6 w-6 text-white" />
                </div>
                <div>
                  <p className="text-sm font-medium">AI Assistant</p>
                  <p
                    className={`text-xs ${
                      isDarkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Always ready to help
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Grid with Visual Elements */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-500/5 to-transparent rounded-3xl" />
        <div className="relative">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Powerful Features</h2>
            <p
              className={`${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              } max-w-2xl mx-auto`}
            >
              Everything you need to create and manage your documentation
              efficiently
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`p-6 rounded-xl backdrop-blur-xl ${
                  isDarkMode
                    ? "bg-gray-800/50 hover:bg-gray-700/50"
                    : "bg-white/80 shadow-lg hover:shadow-xl"
                } transition-all transform hover:-translate-y-1`}
              >
                <div className="bg-gradient-to-br from-blue-500 to-purple-500 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                  <feature.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className={isDarkMode ? "text-gray-300" : "text-gray-600"}>
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Testimonials Section */}
      <div className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-white dark:from-blue-900/10 dark:to-gray-900" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <span className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/10 text-blue-500 mb-4">
              <Sparkles className="h-4 w-4 mr-2" />
              <span className="text-sm font-medium">Customer Stories</span>
            </span>
            <h2 className="text-3xl font-bold mb-4">
              Loved by Teams Worldwide
            </h2>
            <p
              className={`${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              } max-w-2xl mx-auto`}
            >
              See what our customers have to say about their experience with
              Unsloth
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`relative rounded-2xl p-8 ${
                  isDarkMode
                    ? "bg-gray-800/50 backdrop-blur-xl border border-gray-700"
                    : "bg-white shadow-xl"
                } transform hover:-translate-y-1 transition-all duration-300`}
              >
                <div className="absolute top-0 right-0 mt-4 mr-4">
                  <div className="flex space-x-1">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-5 h-5 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                </div>
                <div className="flex items-center mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div className="ml-4">
                    <h4
                      className={`font-semibold ${
                        isDarkMode ? "text-white" : "text-gray-900"
                      }`}
                    >
                      {testimonial.name}
                    </h4>
                    <p
                      className={`text-sm ${
                        isDarkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    >
                      {testimonial.role} at {testimonial.company}
                    </p>
                  </div>
                </div>
                <blockquote>
                  <p
                    className={`text-lg ${
                      isDarkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    "{testimonial.content}"
                  </p>
                </blockquote>
                <div className="absolute bottom-0 right-0 mb-4 mr-4 opacity-10">
                  <svg
                    className="w-12 h-12 text-blue-500"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button
              onClick={() => navigate("/testimonials")}
              className={`inline-flex items-center px-6 py-3 rounded-lg text-white 
                bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 
                hover:to-blue-700 transition-all transform hover:-translate-y-0.5
                shadow-lg hover:shadow-xl`}
            >
              View All Customer Stories
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      {/* Pricing Section with Modern Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-12">
          <span className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/10 text-blue-500 mb-4">
            <Sparkles className="h-4 w-4 mr-2" />
            <span className="text-sm font-medium">Flexible Pricing</span>
          </span>
          <h2 className="text-3xl font-bold mb-4">
            Simple, Transparent Pricing
          </h2>
          <p
            className={`${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            } max-w-2xl mx-auto`}
          >
            Choose the plan that best fits your needs. All plans include our
            core features.
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl ${
                isDarkMode
                  ? "bg-gray-800/50 backdrop-blur-xl border-2 border-gray-700"
                  : "bg-white shadow-xl"
              } ${
                plan.popular ? "ring-2 ring-blue-500 transform scale-105" : ""
              } transition-all hover:-translate-y-1`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0 bg-blue-500 text-white px-3 py-1 rounded-tr-lg rounded-bl-lg text-sm font-medium">
                  Most Popular
                </div>
              )}
              <div className="p-8">
                <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                <div className="flex items-baseline mb-4">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  {plan.price !== "Custom" && (
                    <span className="ml-1 text-gray-500">/month</span>
                  )}
                </div>
                <p
                  className={`mb-6 ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  {plan.description}
                </p>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <Check className="h-5 w-5 text-blue-500 mr-2" />
                      <span
                        className={
                          isDarkMode ? "text-gray-300" : "text-gray-600"
                        }
                      >
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => navigate("/signin")}
                  className={`w-full py-3 rounded-lg font-medium transition-colors ${
                    plan.popular
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : isDarkMode
                      ? "bg-gray-700 text-white hover:bg-gray-600"
                      : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                  }`}
                >
                  {plan.price === "Custom" ? "Contact Sales" : "Get Started"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-12">
          <span className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/10 text-blue-500 mb-4">
            <HelpCircle className="h-4 w-4 mr-2" />
            <span className="text-sm font-medium">FAQ</span>
          </span>
          <h2 className="text-3xl font-bold mb-4">
            Frequently Asked Questions
          </h2>
          <p
            className={`${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            } max-w-2xl mx-auto`}
          >
            Everything you need to know about Unsloth and our services
          </p>
        </div>
        <div className="grid gap-4 max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`${
                isDarkMode
                  ? "bg-gray-800/50 backdrop-blur-xl border border-gray-700"
                  : "bg-white shadow-lg"
              } rounded-xl transition-all hover:shadow-xl`}
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full text-left p-6 focus:outline-none"
              >
                <div className="flex justify-between items-center">
                  <h3
                    className={`text-lg font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {faq.question}
                  </h3>
                  {faq.isOpen ? (
                    <ChevronUp
                      className={`h-5 w-5 ${
                        isDarkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    />
                  ) : (
                    <ChevronDown
                      className={`h-5 w-5 ${
                        isDarkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    />
                  )}
                </div>
                {faq.isOpen && (
                  <p
                    className={`mt-4 ${
                      isDarkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {faq.answer}
                  </p>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* CTA Section with Gradient Background */}
      <div className="relative mt-20 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-white mb-6">
              Ready to streamline your documentation?
            </h2>
            <p className="text-xl text-blue-100 mb-12 max-w-2xl mx-auto">
              Join thousands of teams who trust Unsloth for their documentation
              needs. Start your free trial today.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => navigate("/signin")}
                className="px-8 py-4 text-blue-600 bg-white rounded-lg font-medium hover:bg-gray-100 transition-colors shadow-lg hover:shadow-xl"
              >
                Start Free Trial
              </button>
              <button
                onClick={() => navigate("/signin")}
                className="px-8 py-4 text-white border-2 border-white/20 rounded-lg font-medium hover:bg-white/10 transition-colors"
              >
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section */}
      <div className={`${isDarkMode ? "bg-gray-800" : "bg-gray-50"} py-8`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center space-y-4">
            <div className="flex justify-center space-x-4 mb-4">
              <a
                href="/privacy-policy"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Privacy Policy
              </a>
              <span className={isDarkMode ? "text-gray-600" : "text-gray-400"}>
                •
              </span>
              <a
                href="/docs"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Documentation
              </a>
              <span className={isDarkMode ? "text-gray-600" : "text-gray-400"}>
                •
              </span>
              <a
                href="https://aifanatic.notion.site/Road-Map-13a90e89b9c38031b63dfb2e4e79bc4e?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Road Map
              </a>
              <span className={isDarkMode ? "text-gray-600" : "text-gray-400"}>
                •
              </span>
              <a
                href="/changelog"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Change Log
              </a>
              <span className={isDarkMode ? "text-gray-600" : "text-gray-400"}>
                •
              </span>
              <a
                href="/hall-of-fame"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Hall of Fame
              </a>
              <span className={isDarkMode ? "text-gray-600" : "text-gray-400"}>
                •
              </span>
              <a
                href="/blog"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Blog
              </a>
            </div>
            <p
              className={`text-sm ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Built in collaboration with{" "}
              <a
                href="https://www.ashlingpartners.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Ashling Partners
              </a>{" "}
              🚀
            </p>
            <p
              className={`text-sm ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Designed and Built by{" "}
              <a
                href="https://naveen.aifanatic.pro"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                Naveen 👨🏻‍🔬
              </a>
            </p>
          </div>
        </div>
      </div>
      {showExtensionMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md`}
          >
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
              Chrome Extension
            </h3>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              For updates on the Chrome extension, please reach out to
              naveen.aifanatic@gmail.com
            </p>
            <button
              onClick={() => setShowExtensionMessage(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default LandingPage;
