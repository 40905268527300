import React from "react";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Navbar from "../components/Navbar";

interface Release {
  version: string;
  date: string;
  changes: {
    type: "feature" | "fix" | "improvement";
    description: string;
  }[];
}

const releases: Release[] = [
  {
    version: "1.1.0",
    date: "2024-03-20",
    changes: [
      {
        type: "feature",
        description: "Added support for PowerPoint presentation generation",
      },
      {
        type: "improvement",
        description:
          "Enhanced AI model for more accurate documentation generation",
      },
      {
        type: "fix",
        description: "Fixed dark mode toggle persistence issue",
      },
    ],
  },
  {
    version: "1.0.0",
    date: "2024-03-01",
    changes: [
      {
        type: "feature",
        description: "Initial release with Word and Excel document generation",
      },
      {
        type: "feature",
        description: "Dark mode support",
      },
      {
        type: "feature",
        description: "User authentication and document management",
      },
    ],
  },
];

function ChangeLog() {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate("/")}
          className={`flex items-center mb-8 text-sm font-medium ${
            isDarkMode
              ? "text-gray-300 hover:text-white"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </button>

        <h1
          className={`text-4xl font-bold mb-8 ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Change Log
        </h1>

        <div className="space-y-12">
          {releases.map((release) => (
            <div
              key={release.version}
              className={`${
                isDarkMode ? "bg-gray-800" : "bg-white"
              } rounded-lg shadow-lg p-6`}
            >
              <div className="flex items-center justify-between mb-4">
                <h2
                  className={`text-2xl font-bold ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Version {release.version}
                </h2>
                <span
                  className={`text-sm ${
                    isDarkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  {release.date}
                </span>
              </div>
              <div className="space-y-4">
                {release.changes.map((change, index) => (
                  <div key={index} className="flex items-start">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-3 ${
                        change.type === "feature"
                          ? "bg-green-100 text-green-800"
                          : change.type === "improvement"
                          ? "bg-blue-100 text-blue-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {change.type}
                    </span>
                    <p
                      className={`${
                        isDarkMode ? "text-gray-300" : "text-gray-600"
                      }`}
                    >
                      {change.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ChangeLog;
