import React, { useState, useEffect } from "react";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
import Sidebar from "./Sidebar";
import { ChevronLeft, ChevronRight, Chrome, LogOut } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAnalytics } from "../hooks/useAnalytics";
import { Link } from "react-router-dom";

interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  disableLogoLink?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  title,
  disableLogoLink = false,
}) => {
  const { isDarkMode } = useTheme();
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showExtensionMessage, setShowExtensionMessage] = useState(false);
  const { trackPageView } = useAnalytics();

  // Track page views
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname, trackPageView]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  const handleLogout = () => {
    logout();
    navigate("/signin");
  };
  return (
    <div
      className={`flex h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      {/* Collapsible Sidebar */}
      <div
        className={`relative ${
          isSidebarCollapsed ? "w-16" : "w-64"
        } transition-all duration-300 ease-in-out`}
      >
        <Sidebar collapsed={isSidebarCollapsed} />
        <button
          onClick={toggleSidebar}
          className={`absolute top-4 -right-3 bg-white dark:bg-gray-800 rounded-full p-1 shadow-md z-10`}
        >
          {isSidebarCollapsed ? (
            <ChevronRight className="h-4 w-4" />
          ) : (
            <ChevronLeft className="h-4 w-4" />
          )}
        </button>
      </div>
      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header
          className={`${
            isDarkMode ? "bg-gray-800 border-b border-gray-700" : "bg-white"
          } shadow-sm select-none`}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
            {disableLogoLink ? (
              <div className="cursor-default">
                <div className="flex items-center space-x-4">
                  <h1
                    className={`text-2xl font-bold select-none ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {title}
                  </h1>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full select-none ${
                      isDarkMode
                        ? "bg-blue-900 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    Beta
                  </span>
                </div>
              </div>
            ) : (
              <Link to="/">
                <div className="flex items-center space-x-4">
                  <h1
                    className={`text-2xl font-bold select-none ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {title}
                  </h1>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full select-none ${
                      isDarkMode
                        ? "bg-blue-900 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    Beta
                  </span>
                </div>
              </Link>
            )}
            <div className="flex items-center space-x-4 select-none">
              <button
                onClick={() => setShowExtensionMessage(true)}
                className={`px-3 py-1 rounded-md text-sm ${
                  isDarkMode
                    ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                Chrome Extension
              </button>
              <span className={isDarkMode ? "text-gray-300" : "text-gray-700"}>
                {user?.email}
              </span>
              <button
                onClick={handleLogout}
                className={`p-2 rounded-md ${
                  isDarkMode
                    ? "text-gray-300 hover:bg-gray-700"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
                title="Logout"
              >
                <LogOut className="h-5 w-5" />
              </button>
            </div>
          </div>
        </header>
        <main className="flex-1 overflow-y-auto p-6">{children}</main>
      </div>
      {/* Chrome Extension Message */}
      {showExtensionMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md select-none`}
          >
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
              Chrome Extension
            </h3>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              For updates on the Chrome extension, please reach out to
              naveen.aifanatic@gmail.com
            </p>
            <button
              onClick={() => setShowExtensionMessage(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default PageLayout;
