import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Mail, ArrowLeft } from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { resetPassword } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!email.endsWith("@ashlingpartners.com")) {
      setError("Only @ashlingpartners.com email addresses are allowed");
      return;
    }

    const errorMsg = await resetPassword(email);
    if (errorMsg) {
      setError(errorMsg);
    } else {
      setSuccess("Password reset email sent. Please check your inbox.");
    }
  };

  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        isDarkMode ? "bg-gray-900" : "bg-gray-50"
      }`}
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5" />
        <div className="absolute -top-1/2 -left-1/2 w-full h-full transform rotate-12 bg-gradient-to-br from-blue-500/3 to-purple-500/3 rounded-full" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full transform -rotate-12 bg-gradient-to-br from-purple-500/3 to-blue-500/3 rounded-full" />
      </div>

      {/* Back Button */}
      <button
        onClick={() => navigate("/signin")}
        className={`absolute top-4 left-4 z-20 group flex items-center ${
          isDarkMode
            ? "text-gray-400 hover:text-white"
            : "text-gray-600 hover:text-gray-900"
        }`}
      >
        <ArrowLeft className="h-5 w-5 mr-2 transition-transform group-hover:-translate-x-1" />
        Back to sign in
      </button>

      <div className="relative z-10 w-full max-w-md px-6 py-12">
        <div
          className={`${
            isDarkMode
              ? "bg-gray-800/50 backdrop-blur-xl border border-gray-700"
              : "bg-white/80 backdrop-blur-xl shadow-xl"
          } rounded-2xl p-8`}
        >
          <div className="text-center mb-8">
            <h2
              className={`text-3xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Reset Password
            </h2>
            <p
              className={`mt-2 ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Enter your email to receive a password reset link
            </p>
          </div>

          {error && (
            <div className="mb-6 p-4 rounded-lg bg-red-500/10 border border-red-500/20 text-red-500">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 rounded-lg bg-green-500/10 border border-green-500/20 text-green-500">
              {success}
            </div>
          )}

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                className={`block text-sm font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Email address
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail
                    className={`h-5 w-5 ${
                      isDarkMode ? "text-gray-500" : "text-gray-400"
                    }`}
                  />
                </div>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg transition-colors ${
                    isDarkMode
                      ? "bg-gray-900/50 border-gray-600 text-white placeholder-gray-500"
                      : "bg-white/50 border-gray-300 text-gray-900 placeholder-gray-400"
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="you@ashlingpartners.com"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 rounded-lg text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-xl transition-all"
            >
              Send Reset Link
            </button>
          </form>

          <div className="mt-6 text-center">
            <Link
              to="/signin"
              className={`font-medium ${
                isDarkMode
                  ? "text-blue-400 hover:text-blue-300"
                  : "text-blue-600 hover:text-blue-500"
              }`}
            >
              Back to Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
