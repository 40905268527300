import React, { useEffect } from "react";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Navbar from "../components/Navbar";
// Import images
import BrianImg from "/testimonials/Brian.jpg";
import SwethaImg from "/testimonials/Swetha.jpeg";
import FarzanImg from "/testimonials/Farzan.jpg";
import JoeImg from "/testimonials/Joe.jpg";
import AdamImg from "/testimonials/Adam.jpg";
interface Testimonial {
  name: string;
  role: string;
  company: string;
  image: string;
  content: string;
  rating: number;
}
const Testimonials: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Testimonials component mounted");
    console.log("Current theme:", isDarkMode ? "dark" : "light");
  }, []);
  // Update image paths to use imported images
  const allTestimonials: Testimonial[] = [
    {
      name: "Brian Barrett",
      role: "Managing Director, Business ServicesClient Principal",
      company: "Ashling Partners",
      image: BrianImg,
      content:
        "Documentation can be a tedious and time-consuming endeavor. Thankfully, this solution streamlines the process by enabling you to submit a concise prompt and effortlessly obtain thorough documentation in your preferred format. Give it a try, and you'll be delighted with the results!",
      rating: 5,
    },
    {
      name: "Adam Voll",
      role: "Sr. Manager, Solutions Architecture",
      company: "Ashling Partners",
      image: AdamImg || "https://api.dicebear.com/7.x/avataaars/svg?seed=Adam",
      content:
        "Even in its initial form, UnSloth is already proving to be a gamechanger. One of the primary questions I'm asked by newly-onboarded team members is, 'do you have a good example of an existing SDD that I can use as reference for my first design?' With UnSloth, that same team member would be able to feed their requirements in plain text and immediately receive an output that aligns with best practices. Moreover, it removes bottlenecks around solution design in the project timeline. Using UnSloth, a BA or Developer who aspires to become a Solution Architect is now enabled to create detailed SDDs (or any other documentation) which they would formerly be unable to create without hands-on support. UnSloth truly will open doors for various career paths, and most certainly enable greater delivery efficiency without sacrificing quality. Huge fan, and can't wait to see where it goes!",
      rating: 5,
    },
    {
      name: "Swetha Gorugantu",
      role: "Senior Test Automation Engineer",
      company: "CSG Forte Payment Systems",
      image: SwethaImg,
      content:
        "Unsloth is intuitive and user-friendly, with a clean design that makes navigation effortless. As a Test Engineer, I create multiple test case documents for various requirements on a daily basis. Unsloth has made this easy for me by automating repetitive tasks and enhancing productivity. I was overwhelmed by the number of test scenarios it generated based on a single sentence of information that I have given. Great Initiative, Keep Going!!",
      rating: 5,
    },
    {
      name: "Farzan Tavakoli",
      role: "Sr. Consultant Process Intelligence",
      company: "Ashling Partners",
      image:
        FarzanImg || "https://api.dicebear.com/7.x/avataaars/svg?seed=Farzan",
      content:
        "This web application is a game-changer! Leveraging generative AI, it creates high-quality Word documents and PowerPoint presentations, saving hours. The intuitive design makes it easy for anyone to create content.",
      rating: 5,
    },
    {
      name: "Joe Sasson",
      role: "Sr. Consultant, Machine Learning",
      company: "Ashling Partners",
      image: JoeImg || "https://api.dicebear.com/7.x/avataaars/svg?seed=Lisa",
      content:
        "I've had the opportunity to work with Naveen’s document generation application, and I'm thoroughly impressed with both the technical implementation and practical utility. The combination of React for the frontend and serverless architecture demonstrates excellent modern development practices, creating a responsive and scalable solution.",
      rating: 5,
    },
  ];
  // Add error handling for images
  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = e.target as HTMLImageElement;
    target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${target.alt}`;
  };
  return (
    <div
      className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate("/")}
          className={`flex items-center mb-8 text-sm font-medium ${
            isDarkMode
              ? "text-gray-300 hover:text-white"
              : "text-gray-600 hover:text-gray-900"
          }`}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </button>
        <div className="text-center mb-16">
          <h1
            className={`text-4xl font-bold mb-4 ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            What Our Customers Say
          </h1>
          <p
            className={`${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            } max-w-2xl mx-auto`}
          >
            Read success stories from teams that have transformed their
            documentation process with Unsloth
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {allTestimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`relative rounded-2xl p-8 ${
                isDarkMode
                  ? "bg-gray-800/50 backdrop-blur-xl border border-gray-700"
                  : "bg-white shadow-xl"
              } transform hover:-translate-y-1 transition-all duration-300`}
            >
              <div className="absolute top-0 right-0 mt-4 mr-4">
                <div className="flex space-x-1">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <svg
                      key={i}
                      className="w-5 h-5 text-yellow-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
              </div>
              <div className="flex items-center mb-6">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  onError={handleImageError}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <h4
                    className={`font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {testimonial.name}
                  </h4>
                  <p
                    className={`text-sm ${
                      isDarkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    {testimonial.role} at {testimonial.company}
                  </p>
                </div>
              </div>
              <blockquote>
                <p
                  className={`text-lg ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  "{testimonial.content}"
                </p>
              </blockquote>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
