import React from "react";

import PageLayout from "../components/PageLayout";

import { useTheme } from "../context/ThemeContext";

import { useAuth } from "../context/AuthContext";



const Feedback: React.FC = () => {

  const { isDarkMode } = useTheme();

  const { user, loading } = useAuth();



  if (loading) {

    return (

      <div className="min-h-screen flex items-center justify-center">

        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>

      </div>

    );

  }



  return (

    <PageLayout title="Feedback" disableLogoLink={true}>

      <div className={`max-w-4xl mx-auto p-4 ${isDarkMode ? 'bg-gray-900' : 'bg-white'} rounded-lg shadow-lg`}>

        <div className="w-full flex justify-center">

          <iframe

            src="https://docs.google.com/forms/d/e/1FAIpQLSe-U5imK2TffUgLzi9_LzGR2dQIOyas4fSLopcqHLbJjoz2og/viewform?embedded=true"

            width="100%"

            height="800"

            frameBorder="0"

            marginHeight={0}

            marginWidth={0}

            title="Feedback Form"

            className={`shadow-lg rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}

          >

            Loading…

          </iframe>

        </div>

      </div>

    </PageLayout>

  );

};



export default Feedback;














