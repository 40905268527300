import React, { useState } from "react";
import PageLayout from "../components/PageLayout";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
import { Moon, Sun, Bell, Lock } from "lucide-react";
import { Link } from "react-router-dom";

// Initial document types data
const initialDocumentTypes = [
  {
    id: "1",
    name: "Process Design Document",
    apiEndpoint: "Http_ProcessDesign",
  },
  { id: "2", name: "Power App SDD", apiEndpoint: "Http_PowerAppSDD" },
  { id: "3", name: "UiPath SDD", apiEndpoint: "Http_UiPathSDD" },
  { id: "4", name: "Statement of Work", apiEndpoint: "Http_SOW" },
];

const initialReportTypes = [
  { id: "1", name: "Weekly Presentation", apiEndpoint: "Http_PPT" },
  { id: "2", name: "Ohana Weekly", apiEndpoint: "Http_PPT" },
  { id: "3", name: "Test Case Document", apiEndpoint: "Http_TestCaseDoc" },
];

interface DocumentType {
  id?: string;
  name: string;
  apiEndpoint: string;
}

interface ReportType {
  id?: string;
  name: string;
  apiEndpoint: string;
}

const Settings = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user } = useAuth();
  const [notifications, setNotifications] = useState(true);
  const [twoFactor, setTwoFactor] = useState(false);

  // Admin-only states
  const [documentTypes, setDocumentTypes] =
    useState<DocumentType[]>(initialDocumentTypes);
  const [reportTypes, setReportTypes] =
    useState<ReportType[]>(initialReportTypes);
  const [newDocType, setNewDocType] = useState<DocumentType>({
    name: "",
    apiEndpoint: "",
  });
  const [newReportType, setNewReportType] = useState<ReportType>({
    name: "",
    apiEndpoint: "",
  });
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  const isAdmin = user?.email === "admin-unsloth@ashlingpartners.com";

  // Admin functions
  const handleAddDocumentType = () => {
    if (!newDocType.name || !newDocType.apiEndpoint) {
      setMessage({ text: "Please fill in all fields", type: "error" });
      return;
    }

    const newDoc = {
      id: String(documentTypes.length + 1),
      ...newDocType,
    };
    setDocumentTypes([...documentTypes, newDoc]);
    setNewDocType({ name: "", apiEndpoint: "" });
    setMessage({ text: "Document type added successfully", type: "success" });
  };

  const handleAddReportType = () => {
    if (!newReportType.name || !newReportType.apiEndpoint) {
      setMessage({ text: "Please fill in all fields", type: "error" });
      return;
    }

    const newReport = {
      id: String(reportTypes.length + 1),
      ...newReportType,
    };
    setReportTypes([...reportTypes, newReport]);
    setNewReportType({ name: "", apiEndpoint: "" });
    setMessage({ text: "Report type added successfully", type: "success" });
  };

  const handleDeleteDocumentType = (id: string) => {
    setDocumentTypes(documentTypes.filter((type) => type.id !== id));
    setMessage({ text: "Document type deleted successfully", type: "success" });
  };

  const handleDeleteReportType = (id: string) => {
    setReportTypes(reportTypes.filter((type) => type.id !== id));
    setMessage({ text: "Report type deleted successfully", type: "success" });
  };

  return (
    <PageLayout title="Settings" disableLogoLink={true}>
      <div className="max-w-4xl mx-auto p-4">
        {message && (
          <div
            className={`mb-4 p-4 rounded ${
              message.type === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {message.text}
          </div>
        )}

        {/* Regular Settings (visible to all users) */}
        <div className="space-y-6">
          {/* Theme Settings */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-gray-800" : "bg-white"
            } shadow`}
          >
            <h3
              className={`text-lg font-medium mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Theme
            </h3>
            <div className="flex items-center justify-between">
              <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                Dark Mode
              </span>
              <button
                onClick={toggleTheme}
                className={`p-2 rounded-full ${
                  isDarkMode ? "bg-blue-600" : "bg-gray-200"
                }`}
              >
                {isDarkMode ? (
                  <Moon className="w-5 h-5 text-white" />
                ) : (
                  <Sun className="w-5 h-5 text-gray-800" />
                )}
              </button>
            </div>
          </div>

          {/* Notification Settings */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-gray-800" : "bg-white"
            } shadow`}
          >
            <h3
              className={`text-lg font-medium mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Notifications
            </h3>
            <div className="flex items-center justify-between">
              <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                Email Notifications
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={notifications}
                  onChange={() => setNotifications(!notifications)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>

          {/* Security Settings */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-gray-800" : "bg-white"
            } shadow`}
          >
            <h3
              className={`text-lg font-medium mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Security
            </h3>
            <div className="flex items-center justify-between mb-4">
              <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                Two-Factor Authentication
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={twoFactor}
                  onChange={() => setTwoFactor(!twoFactor)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>

        {/* Admin Settings (only visible to admin user) */}
        {isAdmin && (
          <div className="mt-8 space-y-6">
            <h2
              className={`text-2xl font-bold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Admin Settings
            </h2>

            <div className="grid md:grid-cols-2 gap-6">
              {/* Document Types Section */}
              <div
                className={`p-6 rounded-lg ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } shadow`}
              >
                <h3
                  className={`text-xl font-semibold mb-4 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Document Types
                </h3>
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Document Type Name"
                    value={newDocType.name}
                    onChange={(e) =>
                      setNewDocType({ ...newDocType, name: e.target.value })
                    }
                    className={`w-full p-2 border rounded ${
                      isDarkMode
                        ? "bg-gray-700 text-white border-gray-600"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  <input
                    type="text"
                    placeholder="API Endpoint"
                    value={newDocType.apiEndpoint}
                    onChange={(e) =>
                      setNewDocType({
                        ...newDocType,
                        apiEndpoint: e.target.value,
                      })
                    }
                    className={`w-full p-2 border rounded ${
                      isDarkMode
                        ? "bg-gray-700 text-white border-gray-600"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  <button
                    onClick={handleAddDocumentType}
                    className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Add Document Type
                  </button>
                </div>
                <div className="mt-6 space-y-2">
                  {documentTypes.map((type) => (
                    <div
                      key={type.id}
                      className={`flex items-center justify-between p-3 rounded ${
                        isDarkMode ? "bg-gray-700" : "bg-gray-50"
                      }`}
                    >
                      <div>
                        <p
                          className={`font-medium ${
                            isDarkMode ? "text-white" : "text-gray-900"
                          }`}
                        >
                          {type.name}
                        </p>
                        <p
                          className={`text-sm ${
                            isDarkMode ? "text-gray-300" : "text-gray-500"
                          }`}
                        >
                          {type.apiEndpoint}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          type.id && handleDeleteDocumentType(type.id)
                        }
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Report Types Section */}
              <div
                className={`p-6 rounded-lg ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } shadow`}
              >
                <h3
                  className={`text-xl font-semibold mb-4 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Report Types
                </h3>
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Report Type Name"
                    value={newReportType.name}
                    onChange={(e) =>
                      setNewReportType({
                        ...newReportType,
                        name: e.target.value,
                      })
                    }
                    className={`w-full p-2 border rounded ${
                      isDarkMode
                        ? "bg-gray-700 text-white border-gray-600"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  <input
                    type="text"
                    placeholder="API Endpoint"
                    value={newReportType.apiEndpoint}
                    onChange={(e) =>
                      setNewReportType({
                        ...newReportType,
                        apiEndpoint: e.target.value,
                      })
                    }
                    className={`w-full p-2 border rounded ${
                      isDarkMode
                        ? "bg-gray-700 text-white border-gray-600"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  <button
                    onClick={handleAddReportType}
                    className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Add Report Type
                  </button>
                </div>
                <div className="mt-6 space-y-2">
                  {reportTypes.map((type) => (
                    <div
                      key={type.id}
                      className={`flex items-center justify-between p-3 rounded ${
                        isDarkMode ? "bg-gray-700" : "bg-gray-50"
                      }`}
                    >
                      <div>
                        <p
                          className={`font-medium ${
                            isDarkMode ? "text-white" : "text-gray-900"
                          }`}
                        >
                          {type.name}
                        </p>
                        <p
                          className={`text-sm ${
                            isDarkMode ? "text-gray-300" : "text-gray-500"
                          }`}
                        >
                          {type.apiEndpoint}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          type.id && handleDeleteReportType(type.id)
                        }
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <Link to="/signup" style={{ textDecoration: 'none' }}>
        Don't have an account? Sign Up
      </Link> */}
    </PageLayout>
  );
};

export default Settings;
